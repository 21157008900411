import { Spinner } from 'react-bootstrap';
import { Navigate, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { AuthContext } from '../../context/AuthContext';
var RequireAuth = function (_a) {
    var children = _a.children;
    var _b = useState(true), loading = _b[0], setLoading = _b[1];
    var auth = React.useContext(AuthContext);
    var location = useLocation();
    useEffect(function () {
        if (!auth.loading) {
            setLoading(false);
        }
    }, [auth]);
    if (loading) {
        return (React.createElement("div", { style: { minHeight: "90vh", width: '100%', display: "flex", justifyContent: 'center', alignItems: 'center' } },
            React.createElement(Spinner, { animation: "border" })));
    }
    if (auth.user === null) {
        return (React.createElement(Navigate, { to: "/login", state: { from: location }, replace: true }));
    }
    else {
        return children;
    }
};
export default RequireAuth;
