import "./style.css";
import { Button } from 'react-bootstrap';
import React from 'react';
import SideBar from "../../../components/SideBar";
import { axiosAPI } from '../../../utils/index';
import { getAuth } from 'firebase/auth';
var HomeScreen = function () {
    var logout = function () {
        var auth = getAuth();
        auth.signOut();
    };
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement("div", { id: "sidebar", style: { width: '25%' } },
                React.createElement(SideBar, { marginVertical: 0 })),
            React.createElement("div", { style: { height: '300vh' } })),
        React.createElement(Button, { onClick: function () { return axiosAPI('get', 'http://localhost:8080/'); } }, " Pegarle al backend")));
};
export default HomeScreen;
