import './style.css';
import { Row } from 'react-bootstrap';
import LoginForm from './LoginForm';
import LogoZ from "../../../assets/images/logos/LogoZ.png";
import React from 'react';
var LoginScreen = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'loginScreen' },
            React.createElement("img", { id: "logoZ", src: LogoZ }),
            React.createElement(Row, null,
                React.createElement(LoginForm, null)))));
};
export default LoginScreen;
