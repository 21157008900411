import './style.css';
import { Container, Navbar } from 'react-bootstrap';
import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Logo from "../../assets/images/logos/Logo.png";
import SignedInLinks from './SignedInLinks';
import SignedOutLinks from './SignedOutLinks';
var Header = function () {
    var _a;
    var _b = useState(true), isLoading = _b[0], setIsLoading = _b[1];
    var auth = useContext(AuthContext);
    useEffect(function () {
        if (!auth.loading) {
            setIsLoading(false);
        }
    }, [auth]);
    return (React.createElement("header", null,
        React.createElement(Navbar, { expand: "lg", collapseOnSelect: true, className: "bg-light justify-content-between" },
            React.createElement(Container, null,
                React.createElement(Navbar.Brand, { href: "#" },
                    React.createElement("img", { src: Logo, style: { width: 250, maxHeight: 100 } })),
                React.createElement(Navbar.Toggle, { "aria-controls": "navbarScroll" }),
                React.createElement(Navbar.Collapse, { id: "navbarScroll", className: "justify-content-center" }, !isLoading ? ((_a = auth.user) === null || _a === void 0 ? void 0 : _a.uid) ? React.createElement(SignedInLinks, null) : React.createElement(SignedOutLinks, null) : null)))));
};
export default Header;
