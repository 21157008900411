import './style.css';
import { Row } from 'react-bootstrap';
import LogoZ from "../../../assets/images/logos/LogoZ.png";
import React from 'react';
import SignUpForm from './SignUpForm';
var SignUpScreen = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { id: 'SignUpScreen' },
            React.createElement("img", { id: "logoZ", src: LogoZ, style: { width: 250 } }),
            React.createElement(Row, null,
                React.createElement(SignUpForm, null)))));
};
export default SignUpScreen;
