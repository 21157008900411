var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import './style.css';
import { Button, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import firebaseErrorHandler from '../../../utils/firebaseErrorHandler';
var SignUpForm = function (props) {
    var _a, _b;
    var auth = React.useContext(AuthContext);
    var navigate = useNavigate();
    var location = useLocation();
    //@ts-ignore
    var from = ((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname) || "/";
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState(''), password = _d[0], setPassword = _d[1];
    var _e = useState(''), passwordRepeated = _e[0], setPasswordRepeated = _e[1];
    var _f = useState(''), signupError = _f[0], setSignupError = _f[1];
    var _g = useState(''), passwordError = _g[0], setPasswordError = _g[1];
    var handleSubmit = function (event) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            event.preventDefault();
            if (password === passwordRepeated) {
                auth.emailSignUp(email, password, function () {
                    navigate(from, { replace: true });
                }, function (e) {
                    setSignupError(firebaseErrorHandler(e));
                });
            }
            else {
                setPasswordError('Las contraseñas no coinciden.');
            }
            return [2 /*return*/];
        });
    }); };
    return (React.createElement("form", { id: 'signUpForm', onSubmit: handleSubmit },
        React.createElement(Row, { className: 'text-center' },
            React.createElement("h4", { style: { fontFamily: "sans-serif", marginTop: 10, marginBottom: 10 } }, "Comienza tu aventura hacia la excelencia")),
        React.createElement(Row, null,
            React.createElement("div", { className: "form-group pt-2 pb-2" },
                React.createElement("label", { className: 'pb-1' }, "Email"),
                React.createElement("input", { type: "text", required: true, className: "form-control", onChange: function (e) { setEmail(e.target.value); }, placeholder: "Email" })),
            React.createElement("div", { className: "form-group pt-2 pb-2" },
                React.createElement("label", null, "Password"),
                React.createElement("input", { type: "password", required: true, className: "form-control", onChange: function (e) { setPassword(e.target.value); }, placeholder: "Password" })),
            React.createElement("div", { className: "form-group pt-2 pb-2" },
                React.createElement("label", null, "Repeat Password"),
                React.createElement("input", { type: "password", required: true, className: "form-control", onChange: function (e) { setPasswordRepeated(e.target.value); }, placeholder: "Repeat Password" }),
                React.createElement("p", { className: 'text-danger' }, passwordError ? passwordError : signupError))),
        React.createElement("p", { className: 'text-center font-weight-light' },
            React.createElement(Link, { className: 'text-white text-muted', style: { textDecoration: 'none' }, to: '/login' }, "Ya tiene una cuenta? Logueate.")),
        React.createElement(Row, { className: 'd-flex justify-content-center align-items-center', style: { 'height': 75 } },
            React.createElement(Button, { className: 'w-50', variant: 'success', type: 'submit' }, "Sign Up"))));
};
export default SignUpForm;
