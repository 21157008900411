import './global.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomeScreen from './containers/user/HomeScreen';
import LoginScreen from './containers/auth/LoginScreen';
import React from 'react';
import RequireAuth from './security/RequireAuth';
import SignUpScreen from './containers/auth/SignUpScreen';
var App = function () {
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Routes, null,
            React.createElement(Route, { path: "/", element: React.createElement(RequireAuth, null,
                    React.createElement(HomeScreen, null)) }),
            React.createElement(Route, { path: "/login", element: React.createElement(LoginScreen, null) }),
            React.createElement(Route, { path: "/signup", element: React.createElement(SignUpScreen, null) }))));
};
export default App;
// const [isLoading, setIsLoading] = useState(true)
//     const [user, setUser] = useState(null)
//     const auth = getAuth();
//     onAuthStateChanged(auth, (currentUser) => {
//         setUser(currentUser)
//     })
//     useEffect(() => {
//         setIsLoading(false)
//     },[])
//     if(!isLoading){
//         if(user){
//             return (
//                 <Home></Home>
//             )
//         }else{
//             return (
//                 <Login></Login>
//             )
//         }
//     }else{
//         return( <Spinner animation='border'></Spinner>)
//     }
// };
