import './style.css';
import { Button, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import firebaseErrorHandler from '../../../utils/firebaseErrorHandler';
var LoginForm = function (props) {
    var _a, _b;
    var auth = React.useContext(AuthContext);
    var navigate = useNavigate();
    var location = useLocation();
    //@ts-ignore
    var from = ((_b = (_a = location.state) === null || _a === void 0 ? void 0 : _a.from) === null || _b === void 0 ? void 0 : _b.pathname) || "/";
    var _c = useState(''), email = _c[0], setEmail = _c[1];
    var _d = useState(''), password = _d[0], setPassword = _d[1];
    var _e = useState(''), loginError = _e[0], setLoginError = _e[1];
    var handleSubmit = function (event) {
        event.preventDefault();
        auth.emailSignIn(email, password, function () {
            navigate(from, { replace: true });
        }, function (e) {
            setLoginError(firebaseErrorHandler(e));
        });
    };
    return (React.createElement("form", { id: 'loginForm', onSubmit: handleSubmit },
        React.createElement(Row, { className: 'text-center' },
            React.createElement("h4", { style: { fontFamily: "sans-serif", marginTop: 10, marginBottom: 10 } }, "Bienvenido de vuelta!")),
        React.createElement(Row, null,
            React.createElement("div", { className: "form-group pt-2 pb-2" },
                React.createElement("label", { className: 'pb-1' }, "Email"),
                React.createElement("input", { required: true, type: "text", className: "form-control", onChange: function (e) { return setEmail(e.target.value); }, placeholder: "Email" })),
            React.createElement("div", { className: "form-group pt-2 pb-2" },
                React.createElement("label", null, "Password"),
                React.createElement("input", { required: true, type: "password", className: "form-control", onChange: function (e) { return setPassword(e.target.value); }, placeholder: "Password" }),
                React.createElement("p", { className: 'text-danger' }, loginError ? loginError : ''))),
        React.createElement("p", { className: 'text-center font-weight-light' },
            React.createElement(Link, { className: 'text-white text-muted', style: { textDecoration: 'none' }, to: '/signup' }, " A\u00FAn no tienes cuenta? Registrate.")),
        React.createElement(Row, { className: 'd-flex justify-content-center align-items-center', style: { 'height': 75 } },
            React.createElement(Button, { className: 'w-50', variant: 'success', type: 'submit' }, "Loguear"))));
};
export default LoginForm;
