import 'bootstrap/dist/css/bootstrap.min.css';
import { HashRouter } from "react-router-dom";
import App from './App';
import { AuthProvider } from './context/AuthContext';
import React from 'react';
import firebaseConfig from '../firebaseConfig';
import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth';
import { initializeApp } from "firebase/app";
import { render } from 'react-dom';
//const store = createStore()
// Initialize Firebase
var app = initializeApp(firebaseConfig);
var auth = getAuth(app);
var analytics = getAnalytics(app);
var root = document.getElementById('root');
render(React.createElement(React.StrictMode, null,
    React.createElement(HashRouter, null,
        React.createElement(AuthProvider, { auth: auth },
            React.createElement(App, null)))), root);
