import './style.css';
import { Nav } from 'react-bootstrap';
import React from 'react';
var SignedInLinks = function () {
    return (React.createElement(Nav, { style: { marginLeft: "auto" } },
        React.createElement(Nav.Link, { style: { fontFamily: 'Arial', fontSize: 14, margin: "auto" }, href: '/' }, "Inicio"),
        React.createElement(Nav.Link, { style: { fontFamily: 'Arial', fontSize: 14, margin: "auto" }, href: '/' }, "Menu"),
        React.createElement(Nav.Link, { style: { fontFamily: 'Arial', fontSize: 14, margin: "auto" }, href: '/' }, "Perfil"),
        React.createElement(Nav.Link, { style: { fontFamily: 'Arial', fontSize: 14, margin: "auto" }, href: '/' }, "Sign Out")));
};
export default SignedInLinks;
