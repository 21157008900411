var firebaseConfig = {
    apiKey: "AIzaSyDxu_lYWltuvx6qtR-w3kKJjGl2aMT2Qx0",
    authDomain: "backendwme.firebaseapp.com",
    projectId: "backendwme",
    storageBucket: "backendwme.appspot.com",
    messagingSenderId: "747451574529",
    appId: "1:747451574529:web:8d51207ef71fa6c04e9300",
    measurementId: "G-S64RX8PSLW"
};
export default firebaseConfig;
