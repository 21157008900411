import "./style.css";
import React from 'react';
var SideBarItem = function (_a) {
    var _b = _a.marginVertical, marginVertical = _b === void 0 ? null : _b;
    return (React.createElement("div", { id: "externalContainer" },
        React.createElement("div", { id: "innerContainer" },
            React.createElement("h5", { style: { color: 'white' } }, " Categorias "),
            React.createElement("ul", { style: { marginRight: 'auto', color: 'white' } },
                React.createElement("li", null, "Backend B\u00E1sico "),
                React.createElement("li", null, "Backend Avanzado "),
                React.createElement("li", null, "Backend & Frontend ")))));
};
export default SideBarItem;
