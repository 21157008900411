import "./style.css";
import React from 'react';
import SideBarItem from "../SideBarItem";
var SideBar = function (_a) {
    var _b = _a.marginVertical, marginVertical = _b === void 0 ? null : _b;
    return (React.createElement("div", { id: "container", style: marginVertical ? {} : {} },
        React.createElement(SideBarItem, null),
        React.createElement(SideBarItem, null)));
};
export default SideBar;
